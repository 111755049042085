<template>
  <DashboardSection>
    <template #top-right-button>
      <CustomButton
        @onClick="$router.push({ name: 'AddDocument' })"
        iconClass="plus"
        label="New document"
        color="grey"
        size="small"
        testId="addDocument"
      />
    </template>
    <template #content>
      <div class="docs-row" v-if="docs && docs.documents.length > 0">
        <Tile v-for="(doc, i) in docs.documents" :key="i">
          <template #imageSection>
            <img src="@/assets/icons/document.svg" />
            <img src="@/assets/icons/error.svg" class="error-icon" v-if="doc.documentId === null" />
            <img src="@/assets/icons/tick-green.svg" class="green-tick" v-if="doc.documentId !== null" />
          </template>
          <template #title>
            <div class="title-row">
              <p>Client/Supplier</p>
            </div>
          </template>
          <template #right-section v-if="doc.documentId === null">
            <SmallButton
              label="Add"
              @onClick="$router.push({ name: 'AddDocument', query: { type: doc.type } })"
            ></SmallButton>
          </template>
          <template #desc>
            <div class="name-row">
              <p v-if="doc.type == 'NDA'">{{ commonString.NDA }}</p>
              <p v-if="doc.type == 'MSA'">{{ commonString.MSA }}</p>
            </div>
          </template>
          <template #footer v-if="doc.documentId !== null">
            <div class="footer-row">
              <span>{{ userName }}</span>
              <span>{{ filterDate(doc.document.createdAt) }}</span>
            </div>
          </template>
          <template #menu v-if="doc.documentId !== null">
            <ul>
              <li @click="deleteDoc(doc.documentId)">Remove</li>
              <li @click="downloadDoc(doc.documentId)">Download</li>
            </ul>
          </template>
        </Tile>
      </div>
    </template>
  </DashboardSection>
</template>

<script lang="ts">
import CustomButton from '@/components/atom/CustomButton.vue'
import { ref, defineComponent, computed, onMounted } from 'vue'
import authApi from '@/api/Authenticated'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import Tile from '@/components/atom/Tile.vue'
import moment from 'moment'
import { useStore } from '@/store'
import SmallButton from '@/components/atom/SmallButton.vue'
import commonString from '@/utils/commonStrings'
import commonFunctions from '@/utils/commonFunctions'
export default defineComponent({
  name: 'Documents',
  components: {
    CustomButton,
    DashboardSection,
    Tile,
    SmallButton
  },
  setup() {
    const docs = ref()
    const store = useStore()
    const userName = computed(() =>
      store.state.auth.tokenContent ? store.state.auth.tokenContent.studioName : 'User name'
    )
    function filterDate(date: Date) {
      return moment(date).format('D/MM/YY')
    }
    async function getDocuments() {
      const res = await authApi.getDocuments()
      docs.value = res
      commonFunctions.getRequiredDocuments(docs.value, 'NDA', 'MSA', null)
    }
    onMounted(getDocuments)
    async function deleteDoc(id: number) {
      await authApi.deleteDocument(id).then(function (response) {
        const docIndex = docs.value.documents.findIndex((e: any) => e.documentId === id)
        if (docIndex !== -1) {
          docs.value.documents.splice(docIndex, 1)
        }
        getDocuments()
      })
    }
    async function downloadDoc(id: number) {
      if (id != null) {
        const res = await authApi.downloadDocument(id)
        if (res) {
          window.open(res.link, '_blank')
        }
      }
    }

    return { docs, filterDate, open, deleteDoc, userName, commonString, downloadDoc }
  }
})
</script>

<style scoped lang="sass">
.name-row
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 16px
.footer-row
  display: flex
  justify-content: space-between
  margin-top: 6px
.name-row span:nth-child(2)
  font-size: 12px
.docs-row
  display: flex
  flex-wrap: wrap
  & > *
    margin-right: 1rem
    margin-bottom: 1rem
.title-row p
  font-size: 12px
.green-tick
  padding: 4px 9px
</style>
