
import CustomButton from '@/components/atom/CustomButton.vue'
import { ref, defineComponent, computed, onMounted } from 'vue'
import authApi from '@/api/Authenticated'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import Tile from '@/components/atom/Tile.vue'
import moment from 'moment'
import { useStore } from '@/store'
import SmallButton from '@/components/atom/SmallButton.vue'
import commonString from '@/utils/commonStrings'
import commonFunctions from '@/utils/commonFunctions'
export default defineComponent({
  name: 'Documents',
  components: {
    CustomButton,
    DashboardSection,
    Tile,
    SmallButton
  },
  setup() {
    const docs = ref()
    const store = useStore()
    const userName = computed(() =>
      store.state.auth.tokenContent ? store.state.auth.tokenContent.studioName : 'User name'
    )
    function filterDate(date: Date) {
      return moment(date).format('D/MM/YY')
    }
    async function getDocuments() {
      const res = await authApi.getDocuments()
      docs.value = res
      commonFunctions.getRequiredDocuments(docs.value, 'NDA', 'MSA', null)
    }
    onMounted(getDocuments)
    async function deleteDoc(id: number) {
      await authApi.deleteDocument(id).then(function (response) {
        const docIndex = docs.value.documents.findIndex((e: any) => e.documentId === id)
        if (docIndex !== -1) {
          docs.value.documents.splice(docIndex, 1)
        }
        getDocuments()
      })
    }
    async function downloadDoc(id: number) {
      if (id != null) {
        const res = await authApi.downloadDocument(id)
        if (res) {
          window.open(res.link, '_blank')
        }
      }
    }

    return { docs, filterDate, open, deleteDoc, userName, commonString, downloadDoc }
  }
})
